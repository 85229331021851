<template>
<v-container fluid>
  <MDataTable
    title="Contacts"
    :headers="[
      'First Name',
      'Last Name',
      'Email Address',
      'Contact Number',
    ]"
  ></MDataTable>
  <MNote
    :text="[
      //
    ]"
  ></MNote>
</v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components:{
    //
  },
  computed: mapState({
    //
  }),
  props:[
    //
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>